const FR_TAX = 0.2;

type Currency = "EUR" | "JPY" | "USD";
type Locale = "fr-FR" | "de-DE" | "ja-JP" | "en-EN" | "fr-BE";

export const fromCents = (price?: number | null) => (price ?? 0) / 100;

export const applyTax = (
  priceWithoutTax: number = 0,
  taxAmount: number = FR_TAX,
) => Math.round(priceWithoutTax * (1 + taxAmount));

/**
 * BE CAREFUL, if taxAmount not provided then the default french tax amount is used
 * -
 * The tax has a hard coded value of 20% = 0.2.
 */
export const fromCentsWithTax = (price?: number | null, taxAmount?: number) => {
  const taxMultiplier = taxAmount ?? FR_TAX;
  const priceExclTax = price ?? 0;

  return Math.round(priceExclTax + priceExclTax * taxMultiplier) / 100;
};

export const formatDecimal = (price: number) => {
  const abs = Math.floor(price);
  const dec = (price * 100 - Math.floor(price) * 100).toFixed();

  return `${abs},${dec.length === 2 ? dec : "00"}`;
};

export const formatPriceInCents = (price: number) => {
  return formatDecimal(fromCents(price));
};

export const formatToCurrency = (
  locale: Locale,
  currency: Currency,
  amount: number,
  taxAmount?: number,
  isFractionDigitsHidden: boolean = false,
) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: isFractionDigitsHidden ? 0 : undefined,
  }).format(
    taxAmount ? fromCentsWithTax(amount, taxAmount) : fromCents(amount),
  );

export function priceDiff(a: number, b: number) {
  return Math.round(fromCents(a) - fromCents(b));
}

export function priceAfterDiscount(price: number, discount: number) {
  return Math.round(price - price * (discount / 100));
}
