import cx from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import { BaseButton } from "ui/Button/BaseButton";

type DisabledButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const DisabledButton = ({
  className,
  ...props
}: DisabledButtonProps) => (
  <BaseButton
    className={cx(
      "disabled:text-black/[.50] disabled:bg-platinum disabled:border-black/[.30] disabled:drop-shadow-none disabled:cursor-default",
      className,
    )}
    {...props}
  />
);
