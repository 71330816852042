import cx from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { match } from "ts-pattern";

import { Shadow } from "ui/Button/index";

type BaseButtonExtraProps = {
  shadow?: Shadow;
  borderOnMobile?: boolean;
};

type BaseButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  BaseButtonExtraProps;

const getShadowClass = (shadow: BaseButtonExtraProps["shadow"]) =>
  match(shadow)
    .with(Shadow.White, () => "shadow-[4px_4px_white]")
    .with(Shadow.None, () => undefined)
    .otherwise(() => "shadow-[4px_4px_black]");

const getBorderClass = (
  borderOnMobile: BaseButtonExtraProps["borderOnMobile"],
) =>
  match({ borderOnMobile })
    .with({ borderOnMobile: false }, () => "lg:border lg:border-white")
    .otherwise(() => "border border-black");

export const BaseButton = ({
  className,
  shadow,
  borderOnMobile,
  ...props
}: BaseButtonProps) => (
  <button
    className={cx(
      "transition-all py-2.5 px-6 rounded-full relative",
      className,
      getShadowClass(shadow),
      getBorderClass(borderOnMobile),
    )}
    {...props}
  />
);
