import { match } from "ts-pattern";

import BlackSpinner from "./black-spinner.svg";
import WhiteSpinner from "./white-spinner.svg";

import { Alternative } from "./index";

const getAlternativeLoader = (alternative: Alternative) =>
  match(alternative)
    .with("secondary", () => <BlackSpinner className="animate-spin" />)
    .otherwise(() => <WhiteSpinner className="animate-spin" />);

type ButtonLoaderProps = {
  alternative: Alternative;
};

export const ButtonLoader = ({ alternative }: ButtonLoaderProps) => (
  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
    {getAlternativeLoader(alternative)}
  </div>
);
