import cx from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import { BaseButton } from "ui/Button/BaseButton";
import { Shadow } from "ui/Button/index";

type PushableButtonExtraProps = {
  isDisabled?: boolean;
  shadow?: Shadow;
  borderOnMobile?: boolean;
};

type PushableButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  PushableButtonExtraProps;

export const PushableButton = ({
  isDisabled = false,
  className,
  shadow,
  borderOnMobile,
  ...props
}: PushableButtonProps) => (
  <BaseButton
    className={cx(
      isDisabled
        ? "cursor-default"
        : "active:shadow-[0_0_black] active:translate-x-1 active:translate-y-1",
      className,
    )}
    disabled={isDisabled}
    shadow={shadow}
    borderOnMobile={borderOnMobile}
    {...props}
  />
);
