/* ********************************************************
 *                         ENUMS                          *
 **********************************************************/

import { CountryCode } from "graphql.d";

export enum PotentialCountryCode {
  Be = "BE",
  De = "DE",
  Fr = "FR",
  Lu = "LU",
  Nd = "ND",
}

export enum PhoneCountryCode {
  Be = "BE",
  Fr = "FR",
  Lu = "LU",
}

enum DialingCode {
  Be = 32,
  Fr = 33,
  Lu = 352,
}

enum CountryLabel {
  Be = "Belgique",
  Fr = "France",
  Lu = "Luxembourg",
  Nd = "ND",
}

/* ********************************************************
 *                        MAPPINGS                        *
 **********************************************************/

export const COUNTRY_LABELS = {
  [CountryCode.Be]: CountryLabel.Be,
  [CountryCode.Fr]: CountryLabel.Fr,
  [CountryCode.Lu]: CountryLabel.Lu,
} as const;

export const DIALING_CODES = {
  [CountryCode.Be]: DialingCode.Be,
  [CountryCode.Fr]: DialingCode.Fr,
  [CountryCode.Lu]: DialingCode.Lu,
} as const;

/* ********************************************************
 *                         REGEXS                         *
 **********************************************************/

/**
 * Each match has 3+ groups:
 * 1. The prefix with potentially the dialing code
 * 2. The main part of the phone number
 * 3. The sub groups of the main part.
 *
 * Eg: The French phone number `+33 6 11 22 33 44` will have this RegExpExecArray:
 *
 * | Index |          0          |   1    |        2        |  3  |  4   |  5   |  6   |  7   |
 * | ----- | ------------------- | ------ | --------------- | --- | ---- | ---- | ---- | ---- |
 * | Group | `+33 6 11 22 33 44` | `+33 ` | `6 11 22 33 44` | `6` | `11` | `22` | `33` | `44` |
 */
export const PHONE_REGEXS: Record<string, RegExp> = {
  [CountryCode.Be]:
    /^(0|(?:00|\+)?32\s*0?)?((4\d{2})\s*(\d{2})\s*(\d{2})\s*(\d{2}))$/, // +32 470 11 22 33
  [CountryCode.Fr]:
    /^(0|(?:00|\+)?33\s*0?)?((6|7)\s*(\d{2})\s*(\d{2})\s*(\d{2})\s*(\d{2}))$/, // +33 6 11 22 33 44
  [CountryCode.Lu]: /^((?:00|\+)?352)\s*((\d{1,3})\s*(\d{3})\s*(\d{3}))$/, // +352 600 111 222
};

export const ZIPCODE_REGEXS = {
  [CountryCode.Be]: /\d{4}$/,
  [CountryCode.Fr]: /^(?!(971|972|973|974|975|976|984|986|987|988))\d{5}$/,
  [CountryCode.Lu]: /^L-?\d{4}$/i,
} as const;

export const IBAN_REGEXS = {
  [CountryCode.Be]: /^be/i,
  [CountryCode.De]: /^de/i,
  [CountryCode.Fr]: /^fr/i,
  [CountryCode.Lu]: /^lu/i,
} as const;

/* ********************************************************
 *                 ACCEPTED COUNTRY CODES                 *
 **********************************************************/

export const ACCEPTED_PROPER_PHONE_COUNTRY_CODES = [
  PhoneCountryCode.Be,
  PhoneCountryCode.Fr,
  PhoneCountryCode.Lu,
] as const;

export const ACCEPTED_PHONE_COUNTRY_CODES = [
  CountryCode.Be,
  CountryCode.Fr,
  CountryCode.Lu,
] as const;

export const ACCEPTED_ZIPCODE_COUNTRY_CODES = [
  CountryCode.Be,
  CountryCode.Fr,
  CountryCode.Lu,
] as const;

export const ACCEPTED_IBAN_COUNTRY_CODES = [
  CountryCode.Be,
  CountryCode.De,
  CountryCode.Fr,
  CountryCode.Lu,
] as const;

/* ********************************************************
 *                OPTIONS (for TextSelect)                *
 **********************************************************/

// The order of the values matters
export const COUNTRY_OPTIONS = [
  { value: CountryCode.Fr, label: CountryLabel.Fr },
  { value: CountryCode.Be, label: CountryLabel.Be },
  { value: CountryCode.Lu, label: CountryLabel.Lu },
];

// The order of the values matters
export const DIALING_CODE_OPTIONS = [
  {
    value: PhoneCountryCode.Fr,
    label: `+${DialingCode.Fr} (${CountryLabel.Fr})`,
  },
  {
    value: PhoneCountryCode.Be,
    label: `+${DialingCode.Be} (${CountryLabel.Be})`,
  },
  {
    value: PhoneCountryCode.Lu,
    label: `+${DialingCode.Lu} (${CountryLabel.Lu})`,
  },
];

export const LOCALE_FOR_COUNTRY_CODE: Record<string, string> = {
  BE: "fr_FR",
  DE: "de_DE",
  DK: "da_DK",
  EE: "et_EE",
  ES: "es_ES",
  FI: "fi_FI",
  FR: "fr_FR",
  GB: "en_GB",
  IT: "it_IT",
  LT: "lt_LT",
  LV: "lv_LV",
  NL: "nl_NL",
  NO: "no_NO",
  PL: "pl_PL",
  PT: "pt_PT",
  SE: "sv_SE",
};
