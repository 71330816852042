import cx from "classnames";
import React from "react";

export type HorizontalSeparatorProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHRElement>,
  HTMLHRElement
> & {
  isBlack?: boolean;
};

export const HorizontalSeparator = ({
  className,
  isBlack = false,
  ...props
}: HorizontalSeparatorProps) => (
  <hr
    className={cx(
      "h-0 border-t",
      className,
      isBlack ? "border-black" : "border-gray",
    )}
    {...props}
  />
);
