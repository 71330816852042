import cx from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";

type PlaceholderProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const Placeholder = ({ className }: PlaceholderProps) => (
  <div className={cx("relative rounded-lg border", className)} />
);
