import cx from "classnames";
import { Maybe } from "graphql/jsutils/Maybe";
import pick from "lodash/pick";
import Image from "next/image";
import { MouseEventHandler, useRef, useState } from "react";

import { StrapiImageFragment } from "shared/strapi-image.fragment.generated";
import { Loader } from "ui/Loader";
import { Placeholder } from "ui/StrapiImage/Placeholder";
import { config } from "utils/config.utils";

const LOADER_APPEAR_DELAY_MS = 100;

type StrapiImageMetadata = {
  ext: string;
  url: string | null;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
};

export type StrapiImageSize = "large" | "medium" | "small" | "thumbnail";

export type StrapiImageData = {
  alternativeText: Maybe<string>;
} & {
  [key in StrapiImageSize]: Partial<StrapiImageMetadata>;
};

export type StrapiImageProps = {
  image?: Partial<StrapiImageData>;
  size: StrapiImageSize;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  priority?: boolean;
  loader?: boolean;
  sizes?: string;
};

const SIZES: Array<StrapiImageSize> = ["large", "medium", "small", "thumbnail"];

export const applyCDNToUrl = (url?: string | null) => {
  if (!url) {
    return url;
  }

  try {
    const cdnUrl = new URL(url);
    cdnUrl.host = config.cdn_url;

    return cdnUrl.toString();
  } catch (_) {
    return url;
  }
};

export const stripMetadata = (
  image?: NonNullable<StrapiImageFragment["data"]>["attributes"],
): StrapiImageData | undefined => {
  const strip = (
    imageMetadata?: Partial<StrapiImageMetadata>,
  ): Partial<StrapiImageMetadata> => pick(imageMetadata, "url", "name");

  if (!image) {
    return undefined;
  }

  return {
    alternativeText: image.alternativeText,
    large: strip(image.formats.large),
    medium: strip(image.formats.medium),
    small: strip(image.formats.small),
    thumbnail: strip(image.formats.thumbnail),
  };
};

/**
 * Wrapper for images fetched from Strapi.
 *
 * Due to {@link Image}, width or height must be
 * defined in the `className` props.
 */
export const StrapiImage = ({
  image,
  size,
  className,
  onClick,
  priority,
  loader = false,
  sizes,
}: StrapiImageProps) => {
  const [isLoaderEnabled, setLoaderEnabled] = useState(false);
  const isImageLoaded = useRef(false);

  if (!image) {
    return <Placeholder className={className} />;
  }

  const getLargestSizeAvailable = (requestedSize: StrapiImageSize) => {
    const requestedSizeIndex = SIZES.indexOf(requestedSize);
    const acceptableSizes = SIZES.slice(requestedSizeIndex);

    return acceptableSizes.find((sizeName) => !!image[sizeName]);
  };
  const largestSizeAvailable = getLargestSizeAvailable(size);

  if (!largestSizeAvailable) {
    return <Placeholder className={className} />;
  }

  const largestImage = image[largestSizeAvailable];

  const thumbnailUrl = image?.thumbnail?.url;

  const src = applyCDNToUrl(largestImage?.url);
  const shouldAddKey = className?.includes("animate");

  const handleLoadingStarted = () => {
    setTimeout(() => {
      setLoaderEnabled(loader && !isImageLoaded.current);
    }, LOADER_APPEAR_DELAY_MS);
  };

  const handleLoadingCompleted = () => {
    isImageLoaded.current = true;
    setLoaderEnabled(false);
  };

  return (
    <div
      key={shouldAddKey ? largestImage?.url : null}
      className={cx(
        "relative flex justify-center items-center h-full",
        className,
      )}
      onClick={onClick}
    >
      {isLoaderEnabled && <Loader />}
      {src && (
        <Image
          src={src}
          alt={image.alternativeText ?? ""}
          layout="fill"
          objectFit="contain"
          blurDataURL={applyCDNToUrl(thumbnailUrl) ?? undefined}
          placeholder={thumbnailUrl && !loader ? "blur" : "empty"}
          priority={priority}
          onLoad={handleLoadingStarted}
          onLoadingComplete={handleLoadingCompleted}
          sizes={sizes}
        />
      )}
    </div>
  );
};
