import cx from "classnames";
import { HTMLAttributes } from "react";

type LoaderProps = HTMLAttributes<HTMLSpanElement>;

export const Loader = ({ className, ...props }: LoaderProps) => (
  <span
    className={cx(
      "block w-12 h-12 rounded-full border-4 border-black border-dotted animate-rotate",
      className,
    )}
    {...props}
  />
);
