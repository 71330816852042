/* eslint-disable tailwindcss/no-custom-classname */
import cx from "classnames";
import Script from "next/script";
import React from "react";

import { TRUSTPILOT_BUSINESS_UNIT_ID, TrustpilotTemplate } from "./config";

type TrustpilotWidgetProps = Pick<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "className"
> & {
  template?: TrustpilotTemplate;
  height?: string;
  width?: string;
};

export const TrustpilotWidget = ({
  className,
  template = TrustpilotTemplate.MicroCombo,
  height = "20px",
  width = "100%",
}: TrustpilotWidgetProps) => (
  <div className={cx("cursor-pointer", className)}>
    <Script
      strategy="lazyOnload"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    />

    <div
      className="hidden items-start lg:block trustpilot-widget"
      data-locale="fr-FR"
      data-template-id={template}
      data-businessunit-id={TRUSTPILOT_BUSINESS_UNIT_ID}
      data-style-height={height}
      data-style-width={width}
      data-theme="light"
    />

    <div
      className="lg:hidden trustpilot-widget"
      data-locale="fr-FR"
      data-template-id={TrustpilotTemplate.MicroStar}
      data-businessunit-id={TRUSTPILOT_BUSINESS_UNIT_ID}
      data-style-height={height}
      data-style-width={width}
      data-theme="light"
    />
  </div>
);
